.navbar {
    background-image: linear-gradient(90deg, #47184C 0%, #0E3C68 100%);
    height: 75px;
    width: 100%;
    padding: 20px 30px;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.navbar-logo {
    margin-top: 2px;
}

.navbar-separator {
    border-right: 1px solid #966E50;
    height: 100%;
    width: 25px;
}

.navbar-actions {
    flex: 1;
    margin-right: 20px;
    align-items: center;
    justify-content: flex-end;
    display: flex;
}

.navbar-action-button {
    color: #F7F9FB;
    display: flex;
    gap: 3px;
    background-color: transparent;
    outline: none;
    border: 1px solid transparent;
    font-size: 18px;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
}

.navbar-action-button:hover:not(:disabled) {
    cursor: pointer;
    border-bottom: 1px solid #F7F9FB;
}

.navbar-action-button:disabled {
    cursor: not-allowed;
}
