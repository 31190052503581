.empty-chat-container {
    max-width: 530px;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.empty-chat-container > * {
    margin: 10px;
}

h1 {
    font-size: 28px;
    font-weight: 600;
    color: #26292C;
    padding: 0;
    text-align: center;
}

.subtext {
    font-size: 24px;
    color: #B1B9BF;
    text-align: center;
}