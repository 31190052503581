body {
    margin: 0;
    font-family: 'Inter';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    background-color: #F5F5F5;
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
    box-sizing: border-box;
}

*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.chat-container {
    margin: auto;
    margin-top: 0;
    width: calc(100vw - 500px);
    flex: 1;
    box-sizing: border-box;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    padding-top: 10px;
    overflow-y: auto;
}

.chat-message {
    max-width: 80%;
    padding: 0 15px 5px 15px;
    border-radius: 10px;
}

.user {
    align-self: flex-end;
    background-image: linear-gradient(180deg, #47184C 0%, #0E3C68 100%);
    color: white;
}

.assistant {
    align-self: flex-start;
    background-color: #E7EDF2;
    color: black;
}

.message-wrapper {
    background-color: #FEFEFE;
    min-height: 115px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.message-container {
    margin: 10px auto;
    display: flex;
    gap: 8px;
    width: calc(100vw - 500px);
    min-height: 55px;
}

.message-input-container {
    flex: 1;
    display: flex;
    position: relative;
    border-radius: 15px;
    background-color: #F7F9FB;
    padding-top: 6px;
    height: auto;
    padding-left: 8px;
}

.message-input-container > textarea {
    outline: none;
    border: none;
    background-color: transparent;
    width: 100%;
    padding: 10px 0 0 10px;
    resize: none;
    font-family: 'Inter';
    font-size: 14px;
    height: 32px;
    min-height: 32px;
    max-height: 90px;
    overflow-y: auto;
    margin-bottom: 27px;
}

.message-input:disabled {
    cursor: not-allowed;
}

.message-input::placeholder {
    color: #CBCDCE;
}

.message-file {
    padding: 10px;
}

.footnote {
    color: #848E96;
    margin: auto;
    width: calc(100vw - 500px);
    font-size: 8px;
    margin-top: 0;
    margin-bottom: 10px
}

@media (max-width: 1200px) {
    .chat-container, .message-container, .footnote {
        width: calc(100vw - 200px);
    }
    
}

@media (max-width: 768px) {
    .chat-container, .message-container, .footnote {
        width: calc(100vw - 50px);
    }
}

.file-upload-button {
    margin-left: -35px;
    font-size: 24px;
    cursor: pointer;
    margin-top: 10px;
    outline: none;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.file-upload-button:disabled {
    cursor: not-allowed;
}

.file-input {
    display: none;
}

.submit-button {
    width: 110px;
    border: none;
    border-radius: 15px;
    background-color: #F7F9FB;
    color: #1C1C1C;
    cursor: pointer;
    height: 65px;
}

.submit-button:disabled {
    background-color: #E1E5E8;
    cursor: not-allowed;
}

.send-button {
    display: flex;
    font-size: 16px;
    gap: 5px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.file-overlay {
    position: absolute;
    bottom: 8px; /* Adjust as needed */
    left: 20px; /* Adjust as needed */
    pointer-events: none; /* Allow clicks to go through to the textarea */
}

.selected-file {
    font-size: 14px;
    pointer-events: none; /* Allow clicks to go through to the textarea */
    display: flex;
    gap: 5px;
    align-items: center;
    color: black;
    text-decoration: underline;
}

table {
    border-collapse: collapse;
    color: #26292C;
}

td, th {
    border: 1px solid #966E50;
    padding: 8px;
}

tr:nth-child(even){
    background-color: #F9FBFC;
}

th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #48184C;
    color: white;
}

.step-error {
    padding: 15px;
    padding-left: 0;
}

.step-error > i {
    color: #AD2B3A;
}

.step-error > span {
    color: #AD2B3A;
    margin: 5px;
    font-weight: bolder;
}